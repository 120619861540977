import { apiCustomerJourney } from '../utils/http-common';
import { IVerificationParams, ICustomerJourney } from '../interfaces';

const codeVerification = async (body: IVerificationParams): Promise<ICustomerJourney> => {
    const res = await apiCustomerJourney.post('/qrcode-verify', body);
    if (res.status === 200) {
        const applicationGuid = res.data.id;
        const currentStep = res.data.currentStep.name;
        const status = res.data.status.customerJourneyStatus.value;
        const channel = res.data.channel.channelCode;
        const productData = res.data.product;

        return { channel, currentStep, applicationGuid, status, productData };
    } else {
        throw new Error('Verification failed');
    }
};

export { codeVerification };
