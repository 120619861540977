import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import LoadingPage from '../../components/loading-page/LoadingPage';
import { LOADING_SCREEN, ROUTES_PATH } from '../../constants';

interface Props {
    guid: string;
}

const KyeRedirect: React.FC<Props> = ({ guid }) => {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
        if (guid) {
            if (pathname.includes('creditcard'))
                navigate(`${ROUTES_PATH.CREDIT_CARD}/${guid}`, {
                    replace: true,
                });
            else if (pathname.includes('personalloan'))
                navigate(`${ROUTES_PATH.PERSONAL_LOAN}/${guid}`, {
                    replace: true,
                });
            else navigate('/404', { replace: true });
        }
    });
    return <LoadingPage message={LOADING_SCREEN.MESSAGE} />;
};

export default KyeRedirect;
