import { apiPartners } from '../utils/http-common';
import { IlightsaberVerificationParams, ICustomerJourney } from '../interfaces';

const lightsaberVerification = async (
    body: IlightsaberVerificationParams,
): Promise<ICustomerJourney> => {
    const res = await apiPartners.post('/verify', body);
    if (res.status === 200) {
        const applicationGuid = res.data.id;
        const currentStep = res.data.currentStep.name;
        const status = res.data.status.customerJourneyStatus.value;
        const channel = res.data.channel.channelCode;
        const productData = res.data.product;

        return { channel, currentStep, applicationGuid, status, productData };
    } else {
        throw new Error('Verification failed');
    }
};

export { lightsaberVerification };
