import React, { useEffect, useState } from 'react';
// eslint-disable-next-line import/named, import/no-named-as-default
import ReCAPTCHA from 'react-google-recaptcha';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useLocation, useSearchParams } from 'react-router-dom';
import LoadingPage from '../../components/loading-page/LoadingPage';
import { LOADING_SCREEN } from '../../constants';
import {
    CreateCustomerJourneyProvider,
    ID2CCreateCustomerJourneyParams,
    useCreateCustomerJourneyContext,
} from '../../context/CreateCustomerJourneyContext';
import KyeRedirect from './KyeRedirect';
import { VerificationError } from '../error/VerificationError';

const D2CCreateCustomerJourneyWrapper: React.FC = () => {
    const { executeRecaptcha } = useGoogleReCaptcha();
    const { search, pathname } = useLocation();
    const [searchParams] = useSearchParams();
    const [recaptcha, setRecaptcha] = useState<string>('');

    const { guid, createCustomerJourneyVerifier, isError, recaptchaRef } =
        useCreateCustomerJourneyContext();

    useEffect(() => {
        const getRecaptcha = async () => {
            if (executeRecaptcha) {
                const reCaptchaToken = await executeRecaptcha('create');
                setRecaptcha(reCaptchaToken);
            } else {
                console.log('Execute recaptcha not yet available');
                return;
            }
        };
        getRecaptcha();
    }, [executeRecaptcha]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const getGuid = async () => {
            await createCustomerJourneyVerifier({
                reCaptchaVersion: 'V3',
                reCaptchaToken: recaptcha,
                utmQueryString: search.slice(1),
                productCode: searchParams.get('product_code'),
                productType: pathname.includes('creditcard') ? 'Cards' : 'PersonalLoan',
            } as ID2CCreateCustomerJourneyParams);
        };

        if (recaptcha !== '') getGuid();
    }, [recaptcha]); // eslint-disable-line react-hooks/exhaustive-deps

    if (isError === true) {
        return <VerificationError />;
    } else if (guid !== '') {
        return <KyeRedirect guid={guid} />;
    } else {
        return (
            <>
                <LoadingPage
                    message={LOADING_SCREEN.MESSAGE}
                    submessage1={LOADING_SCREEN.SUB_MESSAGE1}
                    submessage2={LOADING_SCREEN.SUB_MESSAGE2}
                />
                <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey={window.REACT_APP_CAPTCHA_KEY_V2 || ''}
                    onChange={(a) => console.log(a)}
                    size="invisible"
                />
            </>
        );
    }
};

const D2CCreateCustomerJourney: React.FC = () => {
    return (
        <CreateCustomerJourneyProvider>
            <D2CCreateCustomerJourneyWrapper />
        </CreateCustomerJourneyProvider>
    );
};

export default D2CCreateCustomerJourney;
