/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    ICurrentStep,
    IApplicationStatus,
    ICustomerDetailsAbandoned,
    ICustomerDetailsCancelled,
    ICustomerDetailsPending,
    ICustomerJourneyAsync,
    ICustomerUpdateStep,
    IDeclarationItem,
    ICustomerDetailsDuplicate,
    IFileMetaData,
    IFormData,
    IBasicDetails,
    IPersonalDetails,
    IProductData,
    IProductTypes,
    IScreens,
    IUserName,
    IEmploymentDetails,
    IApplicationDetails,
    IChannelCode,
    IDecisionStatus,
    ICustomerDetailsCompleted,
    ICreateCustomerJourney,
    ICustomerJourney,
    IPersonalLoanDetails,
    IPersonalLoanDetailsAsync,
    IBasicDetailsPrefill,
    IPersonalDetailsPrefill,
    IEmploymentDetailsPrefill,
} from '../interfaces';
import { apiCore, apiCustomerJourney } from '../utils/http-common';

const parseDeclarationLinks = (agreementItemVersions: any): IDeclarationItem[] => {
    if (agreementItemVersions && agreementItemVersions.length > 0) {
        return agreementItemVersions.map((v: { label: string; url: string }) => {
            return { label: v?.label, link: v?.url };
        });
    } else {
        return [];
    }
};

// eslint-disable-next-line max-lines-per-function
const parseFormData = (currentStep: IScreens, applicationData: any): IFormData => {
    const formData: IFormData = {};
    if (currentStep === 'PERSONAL_LOAN_CALCULATOR_STEP') {
        const appliedBy = applicationData.currentStep.input as IPersonalLoanDetails;
        formData.loanAmount = appliedBy?.loanAmount;
        formData.loanPurpose = appliedBy?.loanPurpose;
        formData.loanTermInMonths = appliedBy?.loanTermInMonths;
        // formData.monthlyAddOnRate = appliedBy?.monthlyAddOnRate;
        formData.effectiveInterestRate = appliedBy?.effectiveInterestRate;
        formData.monthlyAmortization = appliedBy?.monthlyAmortization;
        formData.totalInterest = appliedBy?.totalInterest;
        formData.totalFeesAndCharges = appliedBy?.totalFeesAndCharges;
        formData.disbursementFee = appliedBy?.disbursementBank;
        formData.documentaryStampTax = appliedBy?.documentaryStampTax;
        formData.others = appliedBy?.others;
        formData.netLoanProceeds = appliedBy?.netLoanProceeds;
        formData.disbursementBank = appliedBy?.disbursementBank;
        // formData.loanTransactionDate = appliedBy?.loanTransactionDate || null;
        // formData.noOfPaymentInYear = appliedBy?.noOfPaymentInYear || null;
        // formData.annualContractualRate = appliedBy?.annualContractualRate || null;
        // formData.monthlyEIR = appliedBy?.monthlyEIR || null;
    } else if (
        currentStep === 'BASIC_DETAILS_STEP' ||
        currentStep === 'BASIC_DETAILS_STEP_READ_ONLY'
    ) {
        const appliedBy = applicationData.currentStep.input as IBasicDetails;

        formData.firstName = appliedBy?.firstName;
        formData.lastName = appliedBy?.lastName;
        formData.middleName = appliedBy?.middleName || null;
        formData.email = appliedBy?.email;
        formData.mobileNumber = appliedBy?.mobileNumber;
        formData.dateOfBirth = appliedBy?.dateOfBirth;
        formData.idType = appliedBy?.idType;
        formData.idValue = appliedBy?.idValue?.toString();
    } else if (
        currentStep === 'PERSONAL_DETAILS_STEP' ||
        currentStep === 'PERSONAL_DETAILS_STEP_READ_ONLY'
    ) {
        const appliedBy = applicationData.currentStep.input as IPersonalDetails;

        formData.blkOrSt = appliedBy?.blkOrSt;
        formData.subdivisionOrBuilding = appliedBy?.subdivisionOrBuilding || null;
        formData.province = appliedBy?.province;
        formData.cityOrTown = appliedBy?.cityOrTown;
        formData.barangay = appliedBy?.barangay;
        formData.zipCode = appliedBy?.zipCode;
        formData.homeOwnership = appliedBy?.homeOwnership || null;
        formData.gender = appliedBy?.gender;
        formData.maritalStatus = appliedBy?.maritalStatus;
        formData.citizenship = appliedBy?.citizenship;
        formData.homePhoneAreaCode = appliedBy?.homePhoneAreaCode || null;
        formData.homePhoneNumber = appliedBy?.homePhoneNumber || null;
        formData.education = appliedBy?.education || null;
        formData.placeOfBirth = appliedBy?.placeOfBirth;
    } else if (
        currentStep === 'EMPLOYMENT_DETAILS_STEP' ||
        currentStep === 'EMPLOYMENT_DETAILS_STEP_READ_ONLY'
    ) {
        const appliedBy = applicationData.currentStep.input as IEmploymentDetails;

        formData.sourceOfFunds = appliedBy?.sourceOfFunds;
        formData.employmentStatus = appliedBy?.employmentStatus;
        formData.occupation = appliedBy?.occupation;
        formData.employer = appliedBy?.employer;
        formData.industry = appliedBy?.industry;
        formData.businessMobileNumber = appliedBy?.businessMobileNumber || null;
        formData.businessPhoneAreaCode = appliedBy?.businessPhoneAreaCode || null;
        formData.businessPhoneNumber = appliedBy?.businessPhoneNumber || null;
        formData.businessEmailAddress = appliedBy?.businessEmailAddress;
        formData.yearsWorkingTotal = appliedBy?.yearsWorkingTotal;
        formData.yearsWorkingWithPresentEmployer = appliedBy?.yearsWorkingWithPresentEmployer;
        formData.grossMonthlyIncome = appliedBy?.grossMonthlyIncome;
        formData.blkOrSt = appliedBy?.blkOrSt;
        formData.subdivisionOrBuilding = appliedBy?.subdivisionOrBuilding || null;
        formData.province = appliedBy?.province;
        formData.cityOrTown = appliedBy?.cityOrTown;
        formData.barangay = appliedBy?.barangay;
        formData.zipCode = appliedBy?.zipCode;
    } else if (currentStep === 'APPLICATION_DETAILS_STEP') {
        const appliedBy = applicationData.currentStep.input as IApplicationDetails;

        formData.emailStatement = appliedBy?.emailStatement;
        formData.financialTransactions = appliedBy?.financialTransactions;
        formData.preferredDeliveryAddress = appliedBy?.preferredDeliveryAddress;
        formData.relationship = appliedBy?.relationship;
        formData.nameOnCard = appliedBy?.nameOnCard;
        // formData.additionalIdType = appliedBy?.additionalIdType;
        // formData.additionalIdValue = appliedBy?.additionalIdValue;
    }

    return formData;
};

const parsePrefillData = (
    currentStep: IScreens,
    formData: IFormData,
    applicationData: any,
): IFormData => {
    if (currentStep === 'BASIC_DETAILS_STEP') {
        const prefilldata = applicationData.mergedPrefill as IBasicDetailsPrefill;
        const arrPrefillData =
            prefilldata && Object.entries(prefilldata).map((e) => ({ [e[0]]: e[1] }));

        if (prefilldata)
            Object.keys(formData).map((val) => {
                const res = arrPrefillData.filter((e) => val === Object.keys(e)[0]);
                if (res.length > 0) {
                    formData[val] = res[0][val].value;
                }
            });
    } else if (currentStep === 'PERSONAL_DETAILS_STEP') {
        const prefilldata = applicationData.mergedPrefill as IPersonalDetailsPrefill;
        const arrPrefillData =
            prefilldata && Object.entries(prefilldata).map((e) => ({ [e[0]]: e[1] }));

        if (prefilldata)
            Object.keys(formData).map((val) => {
                const res = arrPrefillData.filter((e) => val === Object.keys(e)[0]);
                if (res.length > 0) {
                    formData[val] = res[0][val].value;
                }
            });
    } else if (currentStep === 'EMPLOYMENT_DETAILS_STEP') {
        const prefilldata = applicationData.mergedPrefill as IEmploymentDetailsPrefill;
        const arrPrefillData =
            prefilldata && Object.entries(prefilldata).map((e) => ({ [e[0]]: e[1] }));

        if (prefilldata)
            Object.keys(formData).map((val) => {
                const res = arrPrefillData.filter((e) => val === Object.keys(e)[0]);
                if (res.length > 0) {
                    if (
                        val === 'yearsWorkingTotal' ||
                        val === 'yearsWorkingWithPresentEmployer' ||
                        val === 'grossMonthlyIncome'
                    ) {
                        formData[val] = Number(res[0][val].value);
                    } else formData[val] = res[0][val].value;
                }
            });
    }
    return formData;
};

const createCustomerJourney = async (body: ICreateCustomerJourney): Promise<ICustomerJourney> => {
    try {
        const { data } = await apiCustomerJourney.post('/customer-journeys', body);
        const applicationGuid = data.id;
        const currentStep = data.currentStep.name;
        const status = data.status.customerJourneyStatus.value;
        const channel = data.channel.channelCode;
        const productData = data.product;

        return { channel, currentStep, applicationGuid, status, productData };
    } catch (err) {
        return Promise.reject(err);
    }
};

// eslint-disable-next-line max-lines-per-function
const updateStep = async ({
    applicationGuid,
    action,
    stepInput,
    stepName,
    reCaptchaToken,
    reCaptchaVersion,
}: ICustomerUpdateStep): Promise<
    | ICustomerDetailsPending
    | ICustomerDetailsAbandoned
    | ICustomerDetailsCancelled
    | ICustomerDetailsCompleted
    | ICustomerDetailsDuplicate
> => {
    try {
        const body: any = {
            applicationGuid,
            action,
            stepName,
            reCaptchaToken,
            reCaptchaVersion,
        };
        if (stepName === 'KYE_STEP' || stepName === 'TU_PREFILL_CONSENT_STEP') {
            body.stepInput = stepInput;
        } else {
            if (stepInput && Object.keys(stepInput).length !== 0) {
                const data: any = {};
                for (const key in stepInput) {
                    if (typeof stepInput[key as keyof typeof stepInput] === 'string') {
                        data[key] = (stepInput[key as keyof typeof stepInput] as string)?.trim();
                    } else {
                        data[key] = stepInput[key as keyof typeof stepInput];
                    }
                }
                if (stepName === 'PERSONAL_DETAILS_STEP') {
                    data.homePhoneNumber = data.homePhoneNumber ?? null;
                    data.homePhoneAreaCode = data.homePhoneAreaCode ?? null;
                } else if (stepName === 'EMPLOYMENT_DETAILS_STEP') {
                    data.businessPhoneNumber = data.businessPhoneNumber ?? null;
                    data.businessPhoneAreaCode = data.businessPhoneAreaCode ?? null;
                }
                body.stepInput = data;
            }
        }
        const res = await apiCustomerJourney.post(
            `/customer-journeys/${applicationGuid}/step-actions`,
            body,
        );
        const data = res.data as ICustomerJourneyAsync;
        const channelCode: IChannelCode = res.data.channel.channelCode;
        const decisionStatus: IDecisionStatus | undefined = res.data.status.decisionStatus?.value;
        const customerJourneyStatus: IApplicationStatus = data.status.customerJourneyStatus.value;
        const relationshipCode: string | undefined =
            res.data.currentStep?.input?.relationshipCode || undefined;

        let ORNNumber: string | null | undefined =
            data.contactInfo?.orn === undefined ? null : data.contactInfo.orn;
        const currentStep: ICurrentStep = data.currentStep;
        const appliedBy = res.data.contactInfo;
        const userName: { firstName: ''; lastName: '' } =
            { firstName: appliedBy?.firstName, lastName: appliedBy?.lastName } || null;

        const productData: IProductData = {
            code: data?.product.code,
            imageUrl: data?.product.imageUrl,
            name: data?.product.name,
            type: data?.product.type,
            description: data?.product.description,
        };

        const prefillData:
            | IBasicDetailsPrefill
            | IPersonalDetailsPrefill
            | IEmploymentDetailsPrefill
            | undefined = res.data.mergedPrefill;

        if (customerJourneyStatus === 'Duplicate') {
            ORNNumber = data.status.customerJourneyStatus.inProgressApplicationOrn;
            const decisionCode = data.status.customerJourneyStatus.decisionCode;
            const duplicateDecisionStatus = data.status.customerJourneyStatus.status
            return {
                ORNNumber,
                customerJourneyStatus,
                channelCode,
                decisionStatus,
                productData,
                decisionCode,
                duplicateDecisionStatus
            };
        } else if (customerJourneyStatus === 'Cancelled') {
            return { customerJourneyStatus: 'Cancelled', channelCode, decisionStatus };
        } else if (customerJourneyStatus === 'Abandoned') {
            return { customerJourneyStatus: 'Abandoned', channelCode, decisionStatus };
        } else if (customerJourneyStatus === 'Completed') {
            return {
                customerJourneyStatus,
                ORNNumber,
                userName,
                channelCode,
                decisionStatus,
                productData,
            };
        } else {
            const declarationItems: IDeclarationItem[] = parseDeclarationLinks(
                res.data?.agreementItemGroup?.agreementItemVersions,
            );

            const tuPrefillAuthenticationSessionId = res.data?.tuPrefillAuthenticationSession;
            let formData: IFormData = parseFormData(currentStep.name, res.data);
            if (!data.currentStep.input)
                formData = parsePrefillData(currentStep.name, formData, res.data);

            return {
                applicationGuid,
                currentStep,
                formData,
                ORNNumber,
                customerJourneyStatus,
                userName,
                declarationItems,
                channelCode,
                decisionStatus,
                productData,
                relationshipCode,
                tuPrefillAuthenticationSessionId,
                prefillData,
            };
        }
    } catch (err) {
        return Promise.reject(err);
    }
};

// eslint-disable-next-line max-lines-per-function
const getApplicationFromGuid = async (
    applicationGuid: string,
): Promise<
    | ICustomerDetailsPending
    | ICustomerDetailsCancelled
    | ICustomerDetailsAbandoned
    | ICustomerDetailsCompleted
    | ICustomerDetailsDuplicate
> => {
    try {
        const res: { status?: any; statusText?: any; data: ICustomerJourneyAsync } =
            await apiCustomerJourney.get(`/customer-journeys/${applicationGuid}`);
        const { data } = res;
        if (res.status === 200) {
            const customerJourneyStatus: IApplicationStatus =
                data.status.customerJourneyStatus.value;
            const decisionStatus: IDecisionStatus | undefined = data.status.decisionStatus?.value;
            const productData: IProductData = {
                code: data?.product.code,
                imageUrl: data?.product.imageUrl,
                name: data?.product.name,
                type: data?.product.type,
                description: data?.product.description,
            };
            const channelCode: IChannelCode = data.channel.channelCode;
            let ORNNumber: string | null | undefined =
                data.contactInfo?.orn === undefined ? null : data.contactInfo.orn;
            const currentStep: ICurrentStep = data.currentStep;

            const prefillData:
                | IBasicDetailsPrefill
                | IPersonalDetailsPrefill
                | IEmploymentDetailsPrefill
                | undefined = res.data.mergedPrefill;

            if (customerJourneyStatus === 'Cancelled') {
                return {
                    customerJourneyStatus: 'Cancelled',
                    productData: null,
                    channelCode,
                    decisionStatus,
                };
            } else if (customerJourneyStatus === 'Abandoned') {
                return {
                    customerJourneyStatus: 'Abandoned',
                    productData,
                    channelCode,
                    decisionStatus,
                };
            } else if (customerJourneyStatus === 'Completed') {
                const typedUserData = data.contactInfo as IUserName;
                const userName = {
                    firstName: typedUserData.firstName,
                    lastName: typedUserData.lastName,
                };
                return {
                    customerJourneyStatus,
                    ORNNumber,
                    userName,
                    channelCode,
                    decisionStatus,
                    productData,
                };
            } else if (customerJourneyStatus === 'Duplicate') {
                ORNNumber = data.status.customerJourneyStatus.inProgressApplicationOrn;
                const decisionCode = data.status.customerJourneyStatus.decisionCode;
                const duplicateDecisionStatus = data.status.customerJourneyStatus.status
                return {
                    ORNNumber,
                    customerJourneyStatus,
                    channelCode,
                    decisionStatus,
                    productData,
                    decisionCode,
                    duplicateDecisionStatus
                };
            } else {
                const declarationItems: IDeclarationItem[] = parseDeclarationLinks(
                    data?.agreementItemGroup?.agreementItemVersions,
                );
                let formData: IFormData = parseFormData(currentStep.name, res.data);
                if (!data.currentStep.input)
                    formData = parsePrefillData(currentStep.name, formData, res.data);

                let userName = null;
                if (data.contactInfo) {
                    const typedUserData = data.contactInfo as IUserName;
                    userName = {
                        firstName: typedUserData.firstName,
                        lastName: typedUserData.lastName,
                    };
                }
                return {
                    applicationGuid,
                    currentStep,
                    formData,
                    ORNNumber,
                    customerJourneyStatus,
                    userName,
                    declarationItems,
                    productData,
                    channelCode,
                    decisionStatus,
                    prefillData,
                };
            }
        } else {
            throw new Error(res.statusText);
        }
    } catch (err: unknown) {
        return Promise.reject(err);
    }
};

const getLoanDetails = async ({
    loanAmount,
    loanPurpose,
    loanTermInMonths,
}: IFormData): Promise<IPersonalLoanDetailsAsync> => {
    try {
        const body: any = {
            loanAmount,
            loanPurpose,
            loanTermInMonths,
        };
        const { data } = await apiCore.post(`/personal-loan-calculator`, body);
        const { personalLoanDetails } = data;
        return {
            monthlyAddOnRate: personalLoanDetails.monthlyAddOnRate,
            effectiveInterestRate: personalLoanDetails.effectiveInterestRate,
            monthlyAmortization: personalLoanDetails.monthlyAmortization,
            totalInterest: personalLoanDetails.totalInterest,
            totalFeesAndCharges: personalLoanDetails.totalFeesAndCharges,
            disbursementFee: personalLoanDetails.disbursementFee,
            documentaryStampTax: personalLoanDetails.documentaryStampTax,
            others: personalLoanDetails.documentaryStampTax,
            netLoanProceeds: personalLoanDetails.netLoanProceeds,
            disbursementBank: personalLoanDetails.disbursementBank,
            loanTransactionDate: personalLoanDetails.loanTransactionDate,
            noOfPaymentInYear: personalLoanDetails.noOfPaymentInYear,
            annualContractualRate: personalLoanDetails.annualContractualRate,
            monthlyEIR: personalLoanDetails.monthlyEIR,
        };
    } catch (err) {
        return Promise.reject(err);
    }
};

const getUrlForUpload = async ({
    applicationGuid,
    fileMetadata,
}: {
    applicationGuid: string;
    fileMetadata: IFileMetaData;
}): Promise<{ url: string; key: string, fields: any }> => {
    try {
        const body = {
            ...fileMetadata
        }

        const res = await apiCustomerJourney.post(
            `/customer-journeys/${applicationGuid}/upload-url`,
            body,
        );
        if (res.status === 200) {
            return { key: res.data.key, url: res.data.url, fields: res.data.fields };
        } else {
            throw new Error('something went wrong while getting link');
        }
    } catch (err: any) {
        if (err.response && (err.response.data.code === 'INVALID_FILE_NAME_ERROR' || err.response.data.code === 'INVALID_FILE_EXTENSION_ERROR')) {
            return Promise.reject(err);
        } else {
            throw new Error('Something went wrong while getting the link.');
        }
    }
};

const upload = async ({
    url,
    payload
}: {
    url: string;
    payload: any;
}): Promise<{ status: 'SUCCESS' | 'FAILURE' }> => {
    try {
        const formData = new FormData();
        Object.keys(payload).forEach((key) => {
            formData.append(key, payload[key]);
        });
        const res = await apiCustomerJourney.post(url, formData, {
            headers: { accept: '*/*', 'Content-type': "multipart/form-data" },
        });
        if (res.status === 204) {
            return { status: 'SUCCESS' };
        }
        return { status: 'FAILURE' };
    } catch (err) {
        throw new Error('something went wrong while getting link');
    }
};

const uploadConfirmation = async ({
    key,
    applicationGuid,
}: {
    key: string;
    applicationGuid: string;
}): Promise<{ status: 'SUCCESS' | 'FAILURE' }> => {
    try {
        const res = await apiCustomerJourney.post(
            `/customer-journeys/${applicationGuid}/upload-confirmation`,
            { key },
        );
        if (res.status === 200) {
            return { status: 'SUCCESS' };
        }
        return { status: 'FAILURE' };
    } catch (err) {
        throw new Error('');
    }
};

// eslint-disable-next-line max-lines-per-function
const uploadFile = async ({
    file,
    fileMetadata,
    applicationGuid,
    action,
    reCaptchaToken,
    stepName,
    reCaptchaVersion,
}: {
    file: File;
    fileMetadata: IFileMetaData;
    applicationGuid: string;
    action: 'next' | 'previous' | 'cancel';
    reCaptchaToken: string;
    stepName: IScreens;
    reCaptchaVersion: 'V2' | 'V3';
}): Promise<
    | ICustomerDetailsPending
    | ICustomerDetailsAbandoned
    | ICustomerDetailsCancelled
    | ICustomerDetailsCompleted
    | undefined
    | ICustomerDetailsDuplicate
> => {
    try {
        const { key, url, fields } = await getUrlForUpload({
            applicationGuid,
            fileMetadata,
        });
        if (url) {
            const payload = { ...fields, file: file }
            const status = await upload({ url, payload });
            if (status.status === 'SUCCESS') {
                const confirmationStatus = await uploadConfirmation({ key, applicationGuid });
                if (confirmationStatus.status === 'SUCCESS') {
                    return await updateStep({
                        applicationGuid,
                        action,
                        reCaptchaToken,
                        stepInput: {},
                        stepName,
                        reCaptchaVersion,
                    });
                }
            }
        }
    } catch (err: unknown) {
        return Promise.reject(err);
    }
};

const getDeclarationLinks = async (type: IProductTypes): Promise<IDeclarationItem[]> => {
    try {
        const data = await apiCore.get(`/agreement-item-group?productType=${type}`);
        const agreementItemVersions = data.data.agreementItemVersions;
        return parseDeclarationLinks(agreementItemVersions);
    } catch (err: unknown) {
        return Promise.reject(err);
    }
};

export {
    createCustomerJourney,
    updateStep,
    uploadFile,
    getApplicationFromGuid,
    getDeclarationLinks,
    getLoanDetails,
    parseFormData,
    parsePrefillData,
};
