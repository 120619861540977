import axios from 'axios';

const apiCore = axios.create({
    baseURL: window.REACT_APP_API_HOST_CORE,
    headers: {
        'Content-type': 'application/json',
    },
});

const apiCustomerJourney = axios.create({
    baseURL: window.REACT_APP_API_HOST_CUSTOMER_JOURNEY,
    headers: {
        'Content-type': 'application/json',
    },
});

const keepAlive = axios.create({
    baseURL: window.REACT_APP_API_HOST_CUSTOMER_JOURNEY,
    headers: {
        'Content-type': 'application/json',
    },
})

const apiPartners = axios.create({
    baseURL: window.REACT_APP_API_HOST_PARTNERS,
    headers: {
        'Content-type': 'application/json',
    },
});

export { apiCore, apiCustomerJourney, apiPartners, keepAlive };