import React, { useContext, useState, createContext } from 'react';
import { useMutation } from 'react-query';
import { lightsaberVerification } from '../API/lightsaber';
import {
    IChannel,
    ICustomerJourney,
    ILightsaberContext,
    ILightsaberProviderProps,
    IProductData,
    IlightsaberVerificationParams,
} from '../interfaces';

export const LightsaberContext = createContext<ILightsaberContext>({} as ILightsaberContext);

export const LightsaberProvider: React.FC<ILightsaberProviderProps> = ({ children }) => {
    const [isError, setIsError] = useState<boolean>(false);
    const [guid, SetGuid] = useState<string>('');
    const [channel, SetChannel] = useState<IChannel>({} as IChannel);
    const [productData, setProductData] = useState<IProductData>({
        code: '',
        imageUrl: '',
        name: '',
        type: '',
        description: '',
    });

    const lightsaberVerify = useMutation(lightsaberVerification, {
        onSuccess: async (data) => {
            const typedData = data as ICustomerJourney;
            SetGuid(typedData.applicationGuid);
            SetChannel(channel);
            if (typedData.productData) setProductData(typedData.productData);
        },
        onError: () => {
            setIsError(true);
        },
    });

    const lightsaberVerifier = async ({ ac, pc, p92, p93, mbh }: IlightsaberVerificationParams) => {
        await lightsaberVerify.mutateAsync({
            ac,
            pc,
            p92,
            p93,
            mbh,
        });
    };

    return (
        <LightsaberContext.Provider
            value={{ lightsaberVerifier, guid, SetGuid, isError, setIsError, productData, channel }}
        >
            {children}
        </LightsaberContext.Provider>
    );
};

export const useLightsaberContext = (): ILightsaberContext => useContext(LightsaberContext);
