import React, { useContext, useState, createContext, useRef } from 'react';
import { useMutation } from 'react-query';
import { createCustomerJourney } from '../API/application';
import { ICustomerJourney, IProductData, IProductTypes } from '../interfaces';
import { AxiosError } from 'axios';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
// eslint-disable-next-line import/named, import/no-named-as-default
import ReCAPTCHA from 'react-google-recaptcha';

export interface ICreateCustomerJourneyProviderProps {
    children: React.ReactNode;
}

export interface ICreateCustomerJourneyContext {
    isError: boolean;
    setIsError: React.Dispatch<React.SetStateAction<boolean>>;
    guid: string;
    SetGuid: React.Dispatch<React.SetStateAction<string>>;
    createCustomerJourneyVerifier: (querydata: ID2CCreateCustomerJourneyParams) => Promise<void>;
    productData: IProductData;
    recaptchaRef: React.RefObject<ReCAPTCHA>;
}

export interface ID2CCreateCustomerJourneyParams {
    reCaptchaVersion: 'V3' | 'V2';
    reCaptchaToken: string;
    utmQueryString: string;
    productType: IProductTypes;
    productCode?: string;
}

export const CreateCustomerJourneyContext = createContext<ICreateCustomerJourneyContext>(
    {} as ICreateCustomerJourneyContext,
);

export const CreateCustomerJourneyProvider: React.FC<ICreateCustomerJourneyProviderProps> = ({
    children,
}) => {
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [isError, setIsError] = useState<boolean>(false);
    const [guid, SetGuid] = useState<string>('');
    const [productData, setProductData] = useState<IProductData>({
        code: '',
        imageUrl: '',
        name: '',
        type: '',
        description: '',
    });
    const recaptchaRef = useRef<ReCAPTCHA>(null);

    const getToken = async (type: 'V2' | 'V3', action = ''): Promise<string | null> => {
        if (type === 'V2') {
            if (recaptchaRef.current) {
                const token = await recaptchaRef.current.executeAsync();
                recaptchaRef.current.reset();
                return token;
            }
        } else {
            if (!executeRecaptcha) {
                console.log('Execute recaptcha not yet available');
                return '';
            }
            return executeRecaptcha(action);
        }
        return '';
    };

    const createCustomerJourneyVerify = useMutation(createCustomerJourney, {
        onSuccess: async (data) => {
            const typedData = data as ICustomerJourney;
            SetGuid(typedData.applicationGuid);
            if (typedData.productData) setProductData(typedData.productData);
        },
        onError: (err: AxiosError, variables) => {
            if (
                err.response?.status === 400 &&
                err.response.data.message?.includes('CAPTCHA_V3_ERROR')
            ) {
                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                createCustomerJourneyVerifier({
                    reCaptchaVersion: 'V2',
                    // reCaptchaToken: variables.reCaptchaToken,
                    utmQueryString: variables.utmQueryString,
                    productType: variables.productType,
                    productCode: variables.productCode,
                } as ID2CCreateCustomerJourneyParams);
            } else setIsError(true);
        },
    });

    const createCustomerJourneyVerifier = async ({
        reCaptchaVersion,
        reCaptchaToken,
        utmQueryString,
        productType,
        productCode,
    }: ID2CCreateCustomerJourneyParams) => {
        const newReCaptchaToken = await getToken(reCaptchaVersion, 'create');
        await createCustomerJourneyVerify.mutateAsync({
            reCaptchaVersion: reCaptchaVersion,
            reCaptchaToken:
                reCaptchaVersion === 'V2' ? newReCaptchaToken || '' : reCaptchaToken || '',
            action: 'create',
            utmQueryString,
            productCode,
            productType,
        });
    };

    return (
        <CreateCustomerJourneyContext.Provider
            value={{
                isError,
                setIsError,
                guid,
                SetGuid,
                createCustomerJourneyVerifier,
                productData,
                recaptchaRef,
            }}
        >
            {children}
        </CreateCustomerJourneyContext.Provider>
    );
};

export const useCreateCustomerJourneyContext = (): ICreateCustomerJourneyContext =>
    useContext(CreateCustomerJourneyContext);
