import React from 'react';

export const VerificationError: React.FC = () => {
    return (
        <div className="-mt-16 ml-2 mr-2 flex h-screen flex-col items-center justify-center">
            <h1 className="text-3xl font-bold text-primary">
                Oops, there seems to be something wrong with the link but you’re almost there! 
            </h1>
            <h1 className="text-3xl font-bold text-primary">
                Please check the link for any misspelled words or missing characters.
            </h1>
        </div>
    );
};
