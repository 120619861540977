import React, { createContext, useContext, useState } from 'react';
import { useMutation } from 'react-query';
import { codeVerification } from '../API/qrcode';
import {
    ICustomerJourney,
    IProductData,
    IQRCodeContext,
    IVerificationParams,
    QRCodeProviderProps,
} from '../interfaces';

export const QRCodeContext = createContext<IQRCodeContext>({} as IQRCodeContext);

export const QRCodeProvider: React.FC<QRCodeProviderProps> = ({ children }) => {
    const [isError, setIsError] = useState<boolean>(false);
    const [guid, SetGuid] = useState<string>('');
    const [productData, setProductData] = useState<IProductData>({
        code: '',
        imageUrl: '',
        name: '',
        type: '',
        description: '',
    });

    const qrcodeVerify = useMutation(codeVerification, {
        onSuccess: async (data) => {
            const typedData = data as ICustomerJourney;
            SetGuid(typedData.applicationGuid);
            if (typedData.productData) setProductData(typedData.productData);
        },
        onError: () => {
            setIsError(true);
        },
    });

    const qrcodeVerifier = async ({ ac, pc, p92, p93, mbh, productType }: IVerificationParams) => {
        await qrcodeVerify.mutateAsync({
            ac,
            pc,
            p92,
            p93,
            mbh,
            productType,
        });
    };

    return (
        <QRCodeContext.Provider
            value={{ qrcodeVerifier, guid, SetGuid, isError, setIsError, productData }}
        >
            {children}
        </QRCodeContext.Provider>
    );
};

export const useQRCodeContext = (): IQRCodeContext => useContext(QRCodeContext);
