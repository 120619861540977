import React, { useEffect } from 'react';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import LoadingPage from '../../components/loading-page/LoadingPage';
import { LOADING_SCREEN } from '../../constants';
import { IVerificationParams } from '../../interfaces';
import { QRCodeProvider, useQRCodeContext } from '../../context/QRCodeContext';
import QrRedirect from './QrRedirect';
import { VerificationError } from '../error/VerificationError';

const QrcodeVerificationWrapper: React.FC = () => {
    const { search, pathname } = useLocation();
    const { guid, isError, qrcodeVerifier } = useQRCodeContext();

    useEffect(() => {
        const getGuid = async () => {
            const { ac, pc, p92, p93, mbh } = queryString.parse(search);
            await qrcodeVerifier({
                ac,
                pc,
                p92,
                p93,
                mbh,
                productType: pathname.includes('qrcodeverifycc') ? 'Cards' : 'PersonalLoan',
            } as IVerificationParams);
        };
        getGuid();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    if (isError === true) {
        return <VerificationError />;
    } else if (guid === '') {
        return (
            <LoadingPage
                message={LOADING_SCREEN.MESSAGE}
                submessage1={LOADING_SCREEN.SUB_MESSAGE1}
                submessage2={LOADING_SCREEN.SUB_MESSAGE2}
            />
        );
    } else {
        return <QrRedirect guid={guid} />;
    }
};

const QrcodeVerification: React.FC = () => {
    return (
        <QRCodeProvider>
            <QrcodeVerificationWrapper />
        </QRCodeProvider>
    );
};

export default QrcodeVerification;
