import React from 'react';
import { Routes, Route, Navigate, BrowserRouter } from 'react-router-dom';
import ErrorPopup from '../components/error-popup/ErrorPopup';
import ReactSuspense from '../components/react-suspense/ReactSuspense';
import { ROUTES_PATH } from '../constants';
import { useErrorPopUpContext } from '../context/ErrorPopUp';
import D2CCreateCustomerJourney from './D2CCreateCustomerJourney/D2CCreateCustomerJourney';
import LightsaverVerification from './lightsaber-verification/LightsaberVerification';
const CreditCardApply = React.lazy(() => import('./credit-card-apply/CreditCardApply'));
const PersonalLoanApply = React.lazy(() => import('./personal-loan-apply/PersonalLoanApply'));
const AbandonPage = React.lazy(() => import('./abandon/Abandon'));
const AbandonOTPPage = React.lazy(() => import('./abandon/AbandonOTP'));
import PageNotFound from './pageNotFound/PageNotFound';
import Qrcodeverification from './qrcode-verification/QrcodeVerification';

const Pages: React.FC = () => {
    const { onClose, isOpen, message, trackingId } = useErrorPopUpContext();
    return (
        <BrowserRouter>
            <Routes>
                <Route
                    path={`${ROUTES_PATH.CREDIT_CARD}/:guid`}
                    element={<ReactSuspense Component={CreditCardApply} />}
                />
                <Route
                    path={`${ROUTES_PATH.PERSONAL_LOAN}/:guid`}
                    element={<ReactSuspense Component={PersonalLoanApply} />}
                />
                <Route
                    path={`${ROUTES_PATH.CREDIT_CARD_ABANDON}`}
                    element={<ReactSuspense Component={AbandonPage} />}
                />
                <Route
                    path={`${ROUTES_PATH.PERSONAL_LOAN_ABANDON}`}
                    element={<ReactSuspense Component={AbandonPage} />}
                />
                <Route
                    path={`${ROUTES_PATH.CREDIT_CARD_ABANDON}/:resumptionSessionId`}
                    element={<ReactSuspense Component={AbandonOTPPage} />}
                />
                <Route
                    path={`${ROUTES_PATH.PERSONAL_LOAN_ABANDON}/:resumptionSessionId`}
                    element={<ReactSuspense Component={AbandonOTPPage} />}
                />
                <Route path={`/qrcodeverifycc`} element={<Qrcodeverification />} />
                <Route path={`/qrcodeverifypl`} element={<Qrcodeverification />} />
                <Route path={`/lightsaberverifycc`} element={<LightsaverVerification />} />
                <Route path={`/creditcard`} element={<D2CCreateCustomerJourney />} />
                <Route path={`/personalloan`} element={<D2CCreateCustomerJourney />} />
                <Route path="/" element={<Navigate replace to={ROUTES_PATH.CREDIT_CARD} />} />
                <Route path="*" element={<PageNotFound />} />
            </Routes>

            <ErrorPopup
                isOpen={isOpen}
                onClose={onClose}
                message={message}
                trackingId={trackingId}
            />
        </BrowserRouter>
    );
};

export default Pages;
