import React, { useEffect } from 'react';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import LoadingPage from '../../components/loading-page/LoadingPage';
import { LOADING_SCREEN } from '../../constants';
import { IlightsaberVerificationParams } from '../../interfaces';
import { LightsaberProvider, useLightsaberContext } from '../../context/LightsaberContext';
import LightsaberRedirect from './LightsaberRedirect';
import { VerificationError } from '../error/VerificationError';

const LightsaberVerificationWrapper: React.FC = () => {
    const { search } = useLocation();
    const { lightsaberVerifier, guid, isError } = useLightsaberContext();

    useEffect(() => {
        const getGuid = async () => {
            const { ac, pc, p92, p93, mbh } = queryString.parse(search);
            await lightsaberVerifier({
                ac,
                pc,
                p92,
                p93,
                mbh,
            } as IlightsaberVerificationParams);
        };
        getGuid();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    if (isError === true) {
        return <VerificationError />;
    } else if (guid === '') {
        return (
            <LoadingPage
                message={LOADING_SCREEN.MESSAGE}
                submessage1={LOADING_SCREEN.SUB_MESSAGE1}
                submessage2={LOADING_SCREEN.SUB_MESSAGE2}
            />
        );
    } else {
        return <LightsaberRedirect guid={guid} />;
    }
};

const LightsaverVerification: React.FC = () => {
    return (
        <LightsaberProvider>
            <LightsaberVerificationWrapper />
        </LightsaberProvider>
    );
};

export default LightsaverVerification;
